import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RestService } from '@services/rest.service';

@Component({
  selector: 'container',
  templateUrl: './quick-container.component.html',
  styleUrls: ['./quick-container.component.scss']
})
export class QuickContainerComponent implements OnInit {
  codeType:string="";
  code:string;
  constructor(private restService: RestService, private router: Router) {
    this.restService.setDefaultLanguage(); 
   }

  ngOnInit() {
    if(this.router.url.includes('cip'))
    {
      this.code = this.parseQueryString('id'); 
      if(this.code.toString().substr(this.code.toString().length - 2)!="ae")      
        this.codeType = "normal";
      else
      this.codeType = "ae";
    }
  }
  parseQueryString(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

}
