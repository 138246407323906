export class AppConfiguration {
  title: string;
  baseUrl: string;
  appId: { [key: string]: string };
  idmCoreServiceUrl: string;
  usc: string;
  redirect_uri: string;
  countryCode:string;
  idmServiceUrl:string;
  freshDeskUrl:string;
  logoutTime:number;
  serverHost:string;
  bus_no:string;
  poc:string;
  theme:string;
  show_theme:boolean;
  version:string;
  websiteurl:string;
  old_website:string;
  googleAnalyticsId:string;
}
